@import "./_base.scss";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", 
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", 
        "Helvetica Neue", "sans-serif";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Remove scrollbars to side and bottom of page if present (preserves scroll
// ability)
::-webkit-scrollbar {
    display: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        onospace;
}
