/*
Usage SCSS:
    * Import ./index.scss into any stylesheet to access the values below
    * The colors available are:
        * primary-color
        * secondary-color
        * neutral-color
        * green-highlight
        * yellow-highlight
        * red-highlight
    * To access a color, use color-x, where x is a number from 1 to 11, except
    for the neutral-color, where x may be 1 to 13. The lower numbers are lighter
    shades, and the higher numbers are darker shades.

Usage JS (material-ui styles):
    * Import ./index.scss as variables -> see brackets for example
    (import variables from "somePath/index.scss")
    * Access variables using "variables.desiredVariable"
    * Variable names are the same as above, except that all hyphens have been 
    remove, and the resulting name converted to cammelScript

File reserved for universal variable declarations of color, default 
margins etc., and other variables impacting the UI, which should have consistent
values across the application (these changes being propagated automatically by
using scss variables rather than hard-coding the values in each file).

Note that this file is imported into ./index.scss, where some other application
defaults etc. are specified. To use the variables in this file, only 
./index.scss must be imported.

Note that if any of the variables are changed in this file, 
../../public/index.html, and ../../public/manifest.json should be checked, as 
these files are not dependant on any stylesheet within this project. The 
specific values to check are theme_color, and background_color in the manifest 
file, and theme-color in the html file.
*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI",  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",  "Helvetica Neue", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

::-webkit-scrollbar {
  display: none; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", onospace; }

